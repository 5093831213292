
import { Component, Vue, Watch } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import configs from '@/configs'

@Component({
  name: 'AuthEntryBestpay',
  mixins: [GlobalMixin]
})
export default class AuthEntryBestpayPage extends Vue {
  authInit () {
    this.$nextTick(async () => {
      const res = await httpHelper.get({
        url: `ThirdLogin/Sdk?Identity=https://BestpayMc.bestpay.com.cn/&parameter=BestpayMc`
      })
      if (!res || !res.value || !res.value.expand.jsUrl) {
        console.error('Failed to load frontend config', res)
        return
      }
      const config = res.value.expand
      if (!(window as any).BestpayHtml5) {
        // 判断是否是测试环境
        const isTest = (config.jsUrl || '').indexOf('test') > -1
        if (!isTest && !this.$route.query.isBestpayCn) {
          this.$router.replace({
            path: this.$route.path,
            query: {
              ...this.$route.query,
              isBestpayCn: true
            }
          })
          return
        }
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = config.jsUrl
        delete config.jsUrl
        script.onload = () => {
          const BestpayHtml5 = (window as any).BestpayHtml5
          if (BestpayHtml5) {
            BestpayHtml5.config()
            BestpayHtml5.User.userAuth({
              ...config,
              isIOSHV: !(!navigator.userAgent.match(/iphone/i) && !navigator.userAgent.match(/Ipad/i)) || ''
            }, (res: any) => {
              // 用户点击授权弹框确定按钮
              const kvPairs = (getCurrentQuery(undefined) || {}) as { [k: string]: string }
              const params = Object.fromEntries(Object.entries({
                ...kvPairs,
                code: res.code,
                grantType: res.grantType,
                ChannelTag: this.$route.params.channelTag || undefined,
                isBestpayCn: isTest ? undefined : true
              }).filter(([_, value]) => value !== undefined))
              const queryString = new URLSearchParams(params).toString()
              window.location.href = configs.passportApiRoot + 'connect/bestpaymcauth?' + queryString
            })
          }
        }
        script.onerror = () => {
          console.error('Failed to load Bestpay JS file.')
        }
        document.body.appendChild(script)
      }
    })
  }
  mounted () {
    this.authInit()
  }
  @Watch('$route')
  currentRouteChange () {
    this.authInit()
  }
}

